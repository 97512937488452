.footer-logo {
  display: flex;
  margin: auto;
  border-bottom: 2px solid #5F6266;
  align-items: center;
}

.footer-logo__powered {
  color: #5F6266;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 8px;
  font-style: italic;
  padding-right: 2px;
}

.footer-logo__pago {
  color: #000000;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: bold;
  font-weight: bold;
  font-size: 15px;
}

.footer-logo__hub {
  color: #00AAFF;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: bold;
  font-weight: bold;
  font-size: 15px;
}

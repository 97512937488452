.card {
  background-color: #F8F8F8;
}

@media (min-width: 768px) {
  .smaller-card {
      padding: 30px;

      min-width: 700px !important;
      width: 50vw !important;
      max-width: 760px !important;

      min-height: 400px;
      max-height: 50vh !important;
  }
}

@media (max-height: 440px) {
  .smaller-card {
      margin-top: 60px;
      margin-bottom: 60px;

      min-height: 400px;

      overflow-y: auto;
  }
}

@media (min-width: 1024px) {
  .normal-card {
    width: 85vw !important;
    max-height: 85vh !important;
  }

  .bigger-card {
    width: 85vw !important;
    min-height: 80vh !important;
    margin-top: 60px;
    margin-bottom: 60px;
  }
}
